<template>
  <div>
    <div class="data-board" v-for="item in dataBoard">
      <div class="data-board-title">{{ item.title }}</div>
      <div class="data-board-box">
        <div class="data-board-item" v-for="(data, index) in item.content" :class="item.no_icon ? 'no-icon-item' : 'has-icon-item'">
          <div class="item-data">
            <div class="item-title">{{ data.title }}</div>
            <div class="item-num">
              <span :style="{ color: data.text_color ? data.text_color : '' }">{{ data.num }}</span>
              <!-- <span :style="{ color: data.text_color ? data.text_color : '' }" v-if="!data.num">{{ data.minute }}<span style="font-size: 13px">分</span>{{ data.second }}<span style="font-size: 13px">秒</span></span> -->
              <span :style="{ color: data.text_color ? data.text_color : '' }" v-if="!data.num">{{ data.average_num }}</span>
              <span :class="data.plus_or_minus > 0 ? 'red-text' : 'green-text'" class="item-plus-minus" v-if="data.plus_or_minus">{{ data.plus_or_minus > 0 ? "+" : "" }}{{ data.plus_or_minus }}</span>
            </div>
            <div :class="data.percentage > 0 ? 'red-text' : 'green-text'">
              <span class="item-text" v-if="data.percentage">比较上月</span> {{ data.percentage }}<span v-if="data.percentage">%</span>
              <span class="item-text" v-if="data.average_time">{{ data.average_time }}</span>
              <i class="iconfont" v-html="data.percentage > 0 ? '&#xe790;' : '&#xe791;'" :style="{ fontSize: '13px', marginLeft: '5px' }" v-if="data.percentage"></i>
            </div>
          </div>
          <div class="item-icon" v-if="!item.no_icon">
            <i class="iconfont" v-html="data.icon" :style="{ color: data.color, fontSize: `${item.size}px` }" :class="item.border ? 'icon-border' : ''" v-if="data.icon"></i>
            <el-progress type="circle" :show-text="false" :percentage="data.percentum" :status="data.status" v-else></el-progress>
          </div>
          <el-divider direction="vertical" v-if="item.no_icon && index < item.content.length - 1" class="divider"></el-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentDataBoard",
  props: {
    dataBoard: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.data-board {
  width: 100%;
  margin-bottom: 40px;
  .data-board-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 20px;
  }
  .data-board-box {
    display: flex;
    .has-icon-item {
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 8px 8px;
      margin-right: 10px;
      padding: 16px 20px;
    }
    .no-icon-item {
      padding: 16px 0 16px 55px;
    }
    .data-board-item {
      flex: 1;
      height: 134px;
      display: flex;
      justify-content: space-between;
      background: #fff;
      .item-data {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .item-title {
          font-size: 16px;
        }
        .item-num {
          font-size: 28px;
          position: relative;
          .item-plus-minus {
            position: absolute;
            top: 0;
            font-size: 13px;
            margin-left: 5px;
          }
        }
        .item-text {
          font-size: 14px;
          color: #6b747d;
          // margin-right: 6px;
        }
      }
      .item-icon {
        text-align: right;
        line-height: 102px;
      }
    }
  }
}
.red-text {
  color: #ff1743;
}
.green-text {
  color: #1ec1b0;
}
.icon-border {
  padding: 10px;
  border: 1px dashed #e5e5e9;
}
.divider {
  height: 82px;
  margin-top: 10px;
}
/deep/ .el-progress-circle {
  margin-top: 12px;
  width: 80px !important;
  height: 80px !important;
}
</style>
