<template>
  <div class="container-box">
    <div class="main-title">本月浏览量</div>
    <div class="echart-box" id="lookNum" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
    <div class="main-title">本月访问量</div>
    <div class="echart-box" id="visitNum" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
    <div class="main-title">本月平均在线时长</div>
    <div class="echart-box" id="onLineTime" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
    <div class="main-title">人均浏览量</div>
    <div class="echart-box" id="averageLook" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"></div>
  </div>
</template>
<script>
import { getShopFlow } from "../../../utils/apis";

export default {
  name: "FlowShop",
  data() {
    return {
      lookNumData: {
        legend: {
          data: ["老客浏览数", "新客浏览数"],
          right: 30,
        },
        series: [
          {
            name: "老客浏览数",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#0A84FF",
            },
          },
          {
            name: "新客浏览数",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#34C759",
            },
          },
        ],
      },
      visitNumData: {
        legend: {
          data: ["老客浏览数", "新客浏览数"],
          right: 30,
        },
        series: [
          {
            name: "老客浏览数",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#0A84FF",
            },
          },
          {
            name: "新客浏览数",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#34C759",
            },
          },
        ],
      },
      onLineTimeData: {
        title: {
          text: "单位（S）",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 14,
          },
        },
        series: [
          {
            name: "时长",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#0A84FF",
            },
          },
        ],
      },
      averageLookData: {
        series: [
          {
            name: "浏览量",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#34C759",
            },
          },
        ],
      },
      option: {
        title: {
          text: "单位（人次）",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let res = "时间：" + params[0].name + "日";
            for (let i = 0; i < params.length; i++) {
              res += "<br/>" + params[i].marker + params[i].seriesName + " : " + params[i].value;
            }
            return res;
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "30",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
        },
        yAxis: {
          type: "value",
        },
      },
      loadingVal: true,
    };
  },
  methods: {
    async getFlowShop() {
      let res = await getShopFlow({});
      this.lookNumData.series[0].data = res.data.old_browse;
      this.lookNumData.series[1].data = res.data.new_browse;
      this.visitNumData.series[0].data = res.data.old_visit;
      this.visitNumData.series[1].data = res.data.new_visit;
      this.onLineTimeData.series[0].data = res.data.online;
      this.averageLookData.series[0].data = res.data.average_browse;
    },
  },
  mounted() {
    this.lookNum = this.$echarts.init(document.getElementById("lookNum"));
    this.visitNum = this.$echarts.init(document.getElementById("visitNum"));
    this.onLineTime = this.$echarts.init(document.getElementById("onLineTime"));
    this.averageLook = this.$echarts.init(document.getElementById("averageLook"));
    this.getFlowShop();
    setTimeout(() => {
      this.lookNum.setOption({ ...this.option, ...this.lookNumData });
      this.visitNum.setOption({ ...this.option, ...this.visitNumData });
      this.onLineTime.setOption({ ...this.option, ...this.onLineTimeData });
      this.averageLook.setOption({ ...this.option, ...this.averageLookData });
      if (this.lookNumData.series[0].data) this.loadingVal = false;
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 10px;
  }
  .echart-box {
    height: 500px;
    background: #fff;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 30px;
    padding: 30px;
  }
}
</style>
