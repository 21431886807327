<template>
  <div class="container-box">
    <component-data-board :data-board="dataBoard"></component-data-board>
    <div class="main-title">店铺流量与订单</div>
    <div class="echart-top">
      <div v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
        <div class="m-t-15">流量构成</div>
        <div id="flowForm" class="flowForm"></div>
      </div>
      <div v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
        <div class="m-t-15">订单趋势</div>
        <div id="orderTrend" class="orderTrend"></div>
      </div>
    </div>
    <div class="echart-bottom" v-loading="loadingVal" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
      <div class="m-t-15">本月店铺流量趋势</div>
      <div id="trafficTrend" class="trafficTrend"></div>
    </div>
  </div>
</template>
<script>
import ComponentDataBoard from "./ComponentDataBoard.vue";
import { getShopSituation } from "../../../utils/apis";

export default {
  name: "StoreDataOverview",
  components: {
    ComponentDataBoard,
  },
  data() {
    return {
      dataBoard: [
        {
          title: "店铺关注与用户访问数据",
          size: 60,
          border: true,
          content: [
            { title: "店铺关注数", num: void 0, plus_or_minus: void 0, percentage: void 0, icon: "&#xe90e;", color: "#EAF7F7" },
            { title: "新增关注用户数", num: void 0, plus_or_minus: void 0, percentage: void 0, icon: "&#xe64f;", color: "#FFF6E9" },
            { title: "进店访问数", num: void 0, plus_or_minus: void 0, percentage: void 0, icon: "&#xe651;", color: "#EBEFFF" },
            { title: "浏览量", num: void 0, plus_or_minus: void 0, percentage: void 0, icon: "&#xe65b;", color: "#FFE6E9" },
          ],
        },
        {
          title: "店铺用户交易",
          size: 34,
          content: [
            { title: "成交用户数", num: void 0, plus_or_minus: void 0, percentage: void 0, icon: "&#xe65a;", color: "#1677FF" },
            { title: "成交单量", num: void 0, plus_or_minus: void 0, percentage: void 0, icon: "&#xe653;", color: "#28A745" },
            { title: "成交金额", num: void 0, plus_or_minus: void 0, percentage: void 0, icon: "&#xe655;", color: "#28A745" },
            { title: "平均客单价", num: void 0, icon: "&#xe659;", color: "#6F42C1" },
            { title: "成交转化率", num: void 0, percentum: void 0 },
            { title: "本月复购率", num: void 0, plus_or_minus: void 0, percentage: void 0, percentum: void 0, status: "success" },
          ],
        },
      ],
      option: {
        title: {
          text: "单位（个）",
          textStyle: {
            color: "#1D2129",
            fontWeight: "normal",
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let res = "时间：" + params[0].name + "日";
            for (let i = 0; i < params.length; i++) {
              res += "<br/>" + params[i].marker + params[i].seriesName + " : " + params[i].value;
            }
            return res;
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "30",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
        },
        yAxis: {
          type: "value",
        },
      },
      orderTrendData: {
        series: [
          {
            name: "订单数",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#0A84FF",
            },
            areaStyle: {
              opacity: 0.1,
            },
          },
        ],
      },
      trafficTrendData: {
        legend: {
          data: ["老客浏览数", "新客浏览数"],
          right: 30,
        },
        series: [
          {
            name: "老客浏览数",
            type: "line",
            smooth: true,
            symbolSize: 6,
            itemStyle: {
              color: "#0A84FF",
            },
          },
          {
            name: "新客浏览数",
            type: "line",
            smooth: true,
            symbolSize: 6,
            itemStyle: {
              color: "#34C759",
            },
          },
        ],
      },
      flowFormData: {
        tooltip: {
          trigger: "item",
        },
        color: ["#31C5D6", "#3776CF"],
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  // formatter: "{b} : {c}人 ({d}%)",
                  formatter(params) {
                    return `${params.data.name}: ${params.data.value}(${params.data.ratio}%)人`;
                  },
                },
                labelLine: { show: true },
              },
            },
          },
        ],
      },
      loadingVal: true,
    };
  },
  methods: {
    async getStoreData() {
      let res = await getShopSituation({});
      let dataBoardText = [
        { index: 0, text: "shop_situation", sort: 0 },
        { index: 0, text: "user_situation", sort: 1 },
        { index: 0, text: "visit", sort: 2 },
        { index: 0, text: "browse", sort: 3 },
        { index: 1, text: "deal", sort: 0 },
        { index: 1, text: "order", sort: 1 },
        { index: 1, text: "money", sort: 2 },
        { index: 1, text: "repeat", sort: 5 },
      ];
      dataBoardText.map((o) => {
        this.dataBoard[o.index].content[o.sort].num = o.text === "repeat" ? `${res.data[o.text].num}%` : res.data[o.text].num;
        this.dataBoard[o.index].content[o.sort].plus_or_minus = res.data[o.text].increase;
        this.dataBoard[o.index].content[o.sort].percentage = res.data[o.text].month;
      });
      this.dataBoard[1].content[3].num = res.data.price;
      this.dataBoard[1].content[4].num = `${res.data.ratio}%`;
      this.dataBoard[1].content[4].percentum = res.data.ratio;
      this.dataBoard[1].content[5].percentum = res.data.repeat.month;
      let peopleArr = [];
      Object.keys(res.data.people).map((o) => {
        peopleArr.push({ value: res.data.people[o].num, name: o === "old" ? "老客" : "新客", ratio: res.data.people[o].ratio });
      });
      this.flowFormData.series[0].data = peopleArr;
      this.orderTrendData.series[0].data = res.data.order_trend;
      this.trafficTrendData.series[0].data = res.data.old_shop_trend;
      this.trafficTrendData.series[1].data = res.data.new_shop_trend;
    },
  },
  mounted() {
    this.getStoreData();
    this.flowForm = this.$echarts.init(document.getElementById("flowForm"));
    this.orderTrend = this.$echarts.init(document.getElementById("orderTrend"));
    this.trafficTrend = this.$echarts.init(document.getElementById("trafficTrend"));
    setTimeout(() => {
      this.flowForm.setOption(this.flowFormData);
      this.orderTrend.setOption({ ...this.option, ...this.orderTrendData });
      this.trafficTrend.setOption({ ...this.option, ...this.trafficTrendData });
      if (this.orderTrendData.series[0].data) this.loadingVal = false;
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 20px;
  }
  .echart-top {
    display: flex;
    justify-content: space-between;
    height: 400px;
    > div:nth-child(1) {
      width: 600px;
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      margin-right: 30px;
      padding: 30px;
      background: #fff;
      .flowForm {
        width: 100%;
        height: 100%;
      }
    }
    > div:nth-child(2) {
      flex: 1;
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      padding: 30px;
      background: #fff;
      .orderTrend {
        width: 100%;
        height: 100%;
      }
    }
  }
  .echart-bottom {
    height: 680px;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    background: #fff;
    padding: 30px;
    .trafficTrend {
      width: 100%;
      height: 100%;
    }
  }
  .m-t-15 {
    margin-bottom: 15px;
  }
}
</style>
