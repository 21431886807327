<template>
  <div class="container-box">
    <div class="side-nav">
      <div class="nav-item" v-for="item in sideNavArr" :class="item.choice ? 'nav-choice' : ''" @click="onChoiceNav(item)">{{ item.name }}</div>
    </div>
    <div class="main-content">
      <store-data-overview v-if="currentNav === '店铺数据概况'"></store-data-overview>
      <portrait-crowd v-if="currentNav === '人群画像'"></portrait-crowd>
      <flow-shop v-if="currentNav === '店铺流量'"></flow-shop>
      <product-data v-if="currentNav === '商品数据'"></product-data>
      <service-data-overview v-if="currentNav === '客服数据概况'"></service-data-overview>
    </div>
  </div>
</template>
<script>
import StoreDataOverview from "./StoreDataOverview.vue";
import PortraitCrowd from "./PortraitCrowd.vue";
import FlowShop from "./FlowShop.vue";
import ProductData from "./ProductData.vue";
import ServiceDataOverview from "./ServiceDataOverview.vue";

export default {
  name: "DataSummarization",
  components: {
    StoreDataOverview,
    PortraitCrowd,
    FlowShop,
    ProductData,
    ServiceDataOverview,
  },
  data() {
    return {
      sideNavArr: [
        { name: "店铺数据概况", choice: true },
        { name: "人群画像", choice: false },
        { name: "店铺流量", choice: false },
        { name: "商品数据", choice: false },
        { name: "客服数据概况", choice: false },
      ],
      currentNav: "店铺数据概况",
    };
  },
  methods: {
    onChoiceNav(item) {
      this.sideNavArr.map((o) => (o.choice = false));
      item.choice = true;
      this.currentNav = item.name;
      localStorage.setItem("currentNav", this.currentNav);
    },
  },
  created() {
    let res = localStorage.getItem("currentNav");
    res === "店铺数据概况" || !res ? (this.currentNav = "店铺数据概况") : (this.currentNav = res);
    !res ? (this.sideNavArr[0].choice = true) : this.sideNavArr.map((o) => (o.name === res ? (o.choice = true) : (o.choice = false)));
  },
  // mounted() {
  //   window.onbeforeunload = function (e) {
  //     let res = localStorage.getItem("currentNav");
  //     if (!!res) localStorage.removeItem("currentNav");
  //   };
  // },
};
</script>
<style lang="scss" scoped>
.container-box {
  box-sizing: border-box;
  height: 100%;
  background: #f7f8fc;
  display: flex;
  font-family: PingFang SC-Regular, PingFang SC;
  font-size: 14px;
  .side-nav {
    min-width: 189px;
    height: 270px;
    padding: 18px 0;
    background: #fff;
    .nav-item {
      padding-left: 56px;
      line-height: 54px;
      border-left: 4px solid #fff;
      color: #6a686f;
      cursor: pointer;
    }
    .nav-choice {
      border-left: 4px solid #f23b2f;
      color: #f23b2f;
      background: #f7f8fc;
    }
  }
  .main-content {
    flex: 1;
    padding: 50px 40px 50px 50px;
  }
}
</style>
